import gql from 'graphql-tag'

export const AddItem = gql`
  mutation addItem($input: AddItemInput!) {
    addItem(input: $input) {
      order {
        id
        orderToken
      }
    }
  }
`

export const ApplyCoupon = gql`
  mutation applyCoupon($input: ApplyCouponInput!) {
    applyCoupon(input: $input) {
      success
    }
  }
`

export const EditItem = gql`
  mutation editLineItem($input: EditLineItemInput!) {
    editLineItem(input: $input) {
      success
    }
  }
`

export const DeleteItem = gql`
  mutation deleteLineItem($input: DeleteLineItemInput!) {
    deleteLineItem(input: $input) {
      success
    }
  }
`

export const EditOrder = gql`
  mutation editOrder($input: EditOrderInput!) {
    editOrder(input: $input) {
      response {
        success
        data
      }
    }
  }
`

export const CompleteOrder = gql`
  mutation completeOrder($input: CompleteOrderInput!) {
    completeOrder(input: $input) {
      url
    }
  }
`
