<template>
  <div
    >
    <template
      v-if="currentUser && currentUser.selectedProfile.profilableType == 'Store'"
      >
      <v-alert
        style="max-width: 880px !important; margin: 0 auto"
        type="info"
        >
        Deberás ingresar con tu perfil de Fan para poder realizar una compra.
      </v-alert>
    </template>
    <template
      v-else
      >
      <section
        style="max-width: 880px !important; margin: 0 auto"
        class="mb-4"
        v-if="order"
        >
        <v-btn-toggle
          v-model="tabs"
          mandatory
          tile
          color="primary"
          class="fill-width"
          style="height: 40px"
          >
          <v-btn	
            icon
            color="primary"
            >
            <v-icon
              :color="tabs == 0 ? 'primary' : 'grey'"
              small>mdi-cart</v-icon>
          </v-btn>

          <v-btn
            icon
            color="primary"
            :disabled="tabs < 1"
            >
            <v-icon 
              color="primary"
              :color="tabs == 1 ? 'primary' : 'grey'"
              small>mdi-map-marker</v-icon>
          </v-btn>

          <v-btn
            icon
            color="primary"
            :disabled="tabs < 2"
            >
            <v-icon 
              :color="tabs == 2 ? 'primary' : 'grey'"
              small>mdi-truck-fast</v-icon>
          </v-btn>

          <v-btn
            icon
            color="primary"
            :disabled="tabs < 3"
            >
            <v-icon 
              :color="tabs == 3 ? 'primary' : 'grey'"
              small>mdi-file-document-outline</v-icon>
          </v-btn>
        </v-btn-toggle>

        <v-tabs-items  
          :class="$vuetify.breakpoint.lgAndUp ? '' : 'px-2'"
          class="my-3"
          style="background-color: transparent !important"
          v-model="tabs"
          :key="tabs"
          >
          <v-tab-item
            >
            <cart  
              :loading="loading"
              :order="order"
              ref="cart"
              @next="next"
              @reload="fetchOrder"
              />
          </v-tab-item>
          <v-tab-item
            >
            <addresses 
              ref="address"
              @next="next"
              :currentOrder="order"
              :loading="loading"
              />
          </v-tab-item>
          <v-tab-item
            >
            <shipping 
              ref="shipping"
              :currentOrder="order"
              :tile="false"
              :loading="loading"
              @next="next"
              /> 
          </v-tab-item>
          <v-tab-item
            >
            <resume 
              :currentOrder="order"
              :tile="false"
              :loading="loading"
              /> 
          </v-tab-item>
        </v-tabs-items>

        <div 
          class="d-flex align-center justify-space-between px-2"
          >
          <v-btn 
            class="primary" 
            :loading="loading"
            :disabled="loading"
            @click="back" 
            depressed
            >
            Volver
          </v-btn>
          <v-btn 
            :loading="loading"
            :disabled="loading"
            class="success"  
            @click="handleNext"
            >
            {{ tabs == 3 ? 'Ir a WebPay' : 'Siguiente' }}
          </v-btn>
        </div>
      </section>

      <section
        style="max-width: 880px !important; margin: 0 auto"
        class="pa-3"
        v-else
        >
        <v-card
          height="250"
          disabled
          shaped
          outlined
          >
          <v-card-text
            class="d-flex flex-column align-center justify-center fill-height text-subtitle-1"
            >
            <v-icon
              size="75"
              color="secondary"
              >
              mdi-cart-minus
            </v-icon>
            <div
              class="mt-3 primary--text"
              >
              ¡Parece que tu carrito está vacío!
            </div>
          </v-card-text>
        </v-card>
      </section>
    </template>

    <terms
      class="text-center"
    ></terms>
  </div>
</template>

<script>
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

import { mapGetters } from 'vuex'
import { OrderService } from '@/services/storageService'
import { Order } from '@/graphql/queries/orders'
import { CompleteOrder } from '@/graphql/mutations/order'

const cart = () => import('@/components/checkout/Cart')
const addresses = () => import('@/components/checkout/Address')
const resume = () => import('@/components/orders/Detail')
const shipping = () => import('@/components/cart/checkout/Shipping')

const Terms = () => import('@/components/shared/Terms')

export default {
  name: "Cart",

  components:{
    cart,
    shipping,
    addresses,
    resume,
    Terms
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  data:() => ({
    tabs: 0,
    loading: false,
    order: null,
    load: 0,
  }),

  created () {
    this.fetchOrder()
    this.load += 1

    Vue.use(VueGoogleMaps, {
      load: {
        key: "AIzaSyB3RTiKtL9Wx4PT1gwp9FsRGsDu9qJittc",
        libraries: "places", // necessary for places input
        region: process.env.VUE_APP_LOCATION,
        language: "es"
      }
    })
  },

  methods: {
    back () {
      if (this.tabs == 0) {
        this.$router.go(-1)
      } else {
        this.tabs -= 1
      }
    },

    fetchOrder () {
      this.loading = true

      this.$apollo.query({
        query: Order,
        variables: {
          orderToken: OrderService.getToken()
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.loading = false
        this.order = res.data.order
        if (this.order) {
          if (this.order.state == 'completed') {
            OrderService.removeToken()
            this.order = null
          } else {
            OrderService.saveToken(this.order.orderToken)
          }
        }
        this.load += 1
      })
    },

    confirmOrder () {
      this.loading = true

      this.$apollo.mutate({
        mutation: CompleteOrder,
        variables: {
          input: {
            orderToken: OrderService.getToken()
          }
        }
      }).then( res => {
        this.loading = false
        var form = document.createElement("form");

        form.method = "POST";
        form.action = res.data.completeOrder.url;

        document.body.appendChild(form);

        form.submit();
        /* 
        var token = OrderService.getToken()
        OrderService.removeToken()
        this.$router.push({ name: 'Success', params: { orderToken: token } })
         */
      })
    },

    next () {
      this.fetchOrder()
      this.tabs += 1
    },

    handleNext () {
      switch (this.tabs) {
        case 0:
          this.$refs.cart.next()
          break;
        case 1:
          this.$refs.address.next()
          break;
        case 2:
          this.$refs.shipping.next()
          break;
        case 3:
          this.confirmOrder ()
          break;
      }
    }
  }
}
</script>
